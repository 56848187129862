
.sidebar {
  padding: 10px;
  display: flex;
  background-color: #00a289;
  border-radius: 40px;
  box-shadow: 4px 2px 8px rgba(255, 255, 255, 0.656);
  border-radius: 20px;
  ul {
    background-color: #f2e8e1;
    border-radius: 20px;
    padding: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
    list-style: none;
    gap: 20px;
    button {
      display: flex;
      align-items: center;
      gap: 20px;
      width: 310px;
      border-radius: 16px;
      border-bottom: -2px 0 4px rgba(0, 0, 0, 0.4), 4px 4px 4px black;
      box-shadow: inset -2px 0 4px rgba(0, 0, 0, 0.4),
        -2px 0 4px rgba(0, 0, 0, 0.4), 2px 2px 4px #f5f5f5;
      border: none;
      background-color: #f8f8f8;
      .buttonDesign {
        // display: flex;
        // align-self: flex-start;
        background-color: #00a289;
        border-radius: 16px 0 0 16px;
        img {
          width: 60px;
          padding: 6px;
        }
      }
      span {
        font-family: Arial;
        font-size: 18px;
        font-weight: 400;
        display: flex;
        align-self: center;
      }
    }
  }
}