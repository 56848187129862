@font-face {
  font-family: "B Badr";
  src: url("../fonts/B Badr Bold.ttf");
  src: url("../fonts/B Badr Regular.ttf");
}

body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background-color: #f2e8e1;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.container {
  max-width: 1992px;
  width: 100%;
  margin: 0 auto;
}
