.search-form {
  background-color: #00a289;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .input-wrapper {
    position: relative;
    width: 70%;
    input {
      width: 100%;
      height: 65px;
      border-radius: 40px;
      border: white 1px solid;
      text-align: center;
      font-size: 20px;
      box-shadow: 4px 2px 8px rgba(255, 255, 255, 0.656);
      background-color: rgba(255, 255, 255, 0.4);

      &:focus-visible {
        outline: none;
      }
    }

    button {
      cursor: pointer;
      width: 66px;
      height: 65px;
      border-radius: 0 8px 8px 0;
      background: none;
      border: none;
      position: absolute;
      right: 10px;
      top: 0;
    }
  }
}

/* Медиа-запросы */
@media (max-width: 1024px) {
  .search-form {
    .input-wrapper {
      input {
        width: 650px;
      }
    }
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  .search-form {
    .input-wrapper {
      input {
        width: 650px;
      }
    }
  }
}
