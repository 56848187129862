.container {
  padding-top: 32px;
}

header {
  .title {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
    .title_logo {
      position: relative;
      img {
        width: 1000px;
        margin-bottom: 32px;
      }

      .loadingCircle {
        position: absolute;
        left: 350px;
        top: 110px;
        .loader {
          width: 110px;
          height: 110px;
          border: 12px solid rgba(2, 80, 68, 0.4);
          border-top: 12px solid #00a289;
          border-radius: 50%;
          animation: spin 1s linear infinite;
        }
      }
    }

    h1 {
      font-size: 40px;
      margin-bottom: 20px;
    }

    &_persian {
      font-family: "B Badr";
      direction: rtl;
    }
  }
  margin-bottom: 30px;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
.home-search-form {
  height: 180px;
}
/* Медиа-запросы */
@media (max-width: 1024px) {
  header {
    .title .title_logo {
      img {
        width: 700px;
      }

      .loadingCircle {
        left: 240px;
        top: 70px;
        .loader {
          width: 90px;
          height: 90px;
          top: 96px;
        }
      }
    }
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  header {
    .title .title_logo {
      img {
        width: 700px;
      }

      .loadingCircle {
        left: 250px;
        top: 80px;
        .loader {
          width: 80px;
          height: 80px;
          top: 100px;
        }
      }
    }
  }
}

@media (min-width: 1025px) {
  /* Стили для десктопов */
  header {
    .loader {
      width: 110px;
      height: 110px;
      top: 135px;
    }
  }
}
