.container {
  padding-top: 20px;
}
nav {
  display: flex;
  justify-content: space-between;
  padding: 6px 16px;
  .title_logo {
    cursor: pointer;
    position: relative;
    img {
      width: 200px;
    }

    .loadingCircle {
      position: absolute;
      left: 70px;
      top: 20px;
      .loader {
        width: 26px;
        height: 26px;
        border: 4px solid rgba(2, 80, 68, 0.4);
        border-top: 4px solid #00a289;
        border-radius: 50%;
        animation: spin 1s linear infinite;
      }
    }
  }
  .buttons {
    button {
      width: 120px;
      height: 46px;
      border-radius: 8px;
      border: solid 2px #00a289;
      background-color: #f2e8e1;
    }
  }
}

.search-bar {
  background-color: #00a289;
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 20px;
  .search-form-wrapper {
    width: 70%;
    .search-form {
      .input-wrapper {
        width: 100% !important;
      }
    }
  }
}

.mainContent {
  display: flex;
  justify-content: space-around;
  align-items: start;
  padding-bottom: 20px;
  .wordList {
    padding: 40px;
    overflow: auto;
    width: 70%;
    // height: 800px;
    border-radius: 40px;
    border: #00a289 1px solid;
    background-color: rgb(255, 255, 255, 0.2);
    box-shadow: 4px 2px 8px rgba(255, 255, 255, 0.656);
    .nameList {
      width: 100%;
      list-style: none;
      .name {
        cursor: pointer;
        border-bottom: black 1px solid;
        margin-bottom: 20px;
        display: flex;
        justify-content: space-between;
        &:hover {
          transform: scale(1.01);
        }
      }
    }
    .wordList_title {
      width: 100%;
      display: flex;
      justify-content: space-between;
      .persian {
        font-family: B Badr;
        direction: rtl;
      }
    }
    p {
      font-size: 24px;
      font-family: B Badr;
      direction: rtl;
      .link-to-content {
        color: blue;
        cursor: pointer;
        text-decoration: underline;
      }
    }
    .loader {
      width: 26px;
      height: 26px;
      border: 4px solid rgba(2, 80, 68, 0.4);
      border-top: 4px solid #00a289;
      border-radius: 50%;
      animation: spin 1s linear infinite;
      margin: 0 auto;
    }
  }
}
